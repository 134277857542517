import React from 'react'
import Router from 'next/router'
import { apiService, userService } from 'value-studio-sdk'
import { parseCookie } from 'utils'

export default class AuthRequired extends React.Component {
  static async getInitialProps({req, res, asPath}) {
    let cookie
    let redirectUrl

    if (req) {
      // serverside
      cookie = req.headers.cookie
      redirectUrl = req.url
    } else {
      // clientside
      cookie = document.cookie
      redirectUrl = asPath
    }

    let idToken = !cookie ? undefined : parseCookie(cookie)['auth_id_token']

    let reqInterceptor = (config) => {
      if(idToken) {
        config.headers.common.Authorization = `Bearer ${idToken}`
      } else {
        delete config.headers.common.Authorization
      }

      return config
    }

    let resInterceptors = [(response) => {
      return response
    }, (error) => {
      // if the error is a Cancel (thrown because of a 401 response, return
      if (error.constructor && error.constructor.name == 'Cancel') throw error

      // if the error is a 401 response, redirect and cancel all other requests
      if (error.response && error.response.status == 401) {

        let url = `/sign-in?next=${encodeURIComponent(redirectUrl)}`

        if (redirectUrl == '/') {
          url = `/sign-in`
        }

        if (req) {
          if (res.headersSent) {
            throw error
          }

          res.writeHead(302, { Location: url })
          res.end()

          throw error
        }

        Router.push({
          pathname: '/sign-in',
          query: {
            next: encodeURIComponent(redirectUrl)
          }
        })

        apiService.cancelSource.cancel('Request cancelled: Client unauthorized')
        throw error
      }

      throw error
    }]

    apiService.interceptors.request.handlers = []
    apiService.interceptors.response.handlers = []

    apiService.interceptors.request.use(reqInterceptor)
    apiService.interceptors.response.use(...resInterceptors)

    let user = await userService.getProfile().catch(e => {
      return undefined
    })

    return { user }
  }
}

