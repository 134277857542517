import Mousetrap from 'mousetrap'
import { withRouter } from 'next/router'
import CompanyFinder from 'components/company/CompanyFinder'

export default withRouter(class HomeSearch extends React.Component {
  constructor(props) {
    super(props)

    this.companyFinder = React.createRef()
  }

  componentDidMount() {
    Mousetrap.bind('/', (e) => {
      e.preventDefault()
      this.companyFinder.current.focus()
    })
  }

  componentWillUnmount() {
    Mousetrap.unbind('/')
  }

  render() {
    return (
      <div style={{margin: '12vw auto 1.5em', width: '30em'}}>
        <h1 className="text-center font-weight-bold">
          Value Investor Studio
        </h1>
        <div className="my-3" style={{ maxWidth: '30em' }}>
          <CompanyFinder router={this.props.router} ref={this.companyFinder}/>
        </div>
      </div>
    )
  }
})
