export function parseCookie(s) {
  return s.split('; ').map(s => {
    return s.split('=')
  }).reduce((acc, cur) => {
    acc[cur[0]] = cur[1]
    return acc
  }, {})
}

export function abbrNum(num) {
  let m = 1000000
  let b = m*1000

  if (num >= b || num <= -b) return `${(num/b).toLocaleString(undefined, {
    maximumFractionDigits: 1
  })}B`

  if (num >= m || num <= -m) return `${(num/m).toLocaleString(undefined, {
    maximumFractionDigits: 1
  })}M`

  return num.toLocaleString()
}

export default {
  parseCookie,
  abbrNum
}
