import AuthRequired from 'components/auth/AuthRequired'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Head from 'next/head'

import Layout from '../components/Layout'
import HomeSearch from '../components/HomeSearch'


export default class Page extends AuthRequired {
  render() {
    return (
      <Layout showNav={true} hideSearch={true}>
        <Head>
          <title>Value Investor Studio</title>
        </Head>
        <Container>
          <Row>
            <Col>
              <div className="d-flex">
                <HomeSearch/>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}
